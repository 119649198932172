<template>
    <div>
        <div class="addPersonContainer">
            <a-button @click="showSelectPerson">添加人员</a-button>
        </div>
        <a-table rowKey="uuid" :columns="columns" :data-source="data" :pagination="false">
            <span slot="name" slot-scope="text, record">
                <OpenData
                    :type="getDataType(record)"
                    :openid="record.userId || record.deptId"
                    :corpid="record.corpId"
                ></OpenData>
            </span>
            <span slot="userId" slot-scope="text, record">
                {{ record.userId || record.deptId }}
            </span>
            <span slot="corpName" slot-scope="text, record">
                <OpenData type="departmentName" :openid="'1'" :corpid="record.corpId"></OpenData>
            </span>
            <span slot="action" slot-scope="text, record">
                <a-popconfirm
                    title="确定删除该人员？"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="confirm(text, record)"
                >
                    <a-button> 删除 </a-button>
                </a-popconfirm>
            </span>
        </a-table>
    </div>
</template>
<style scoped lang="less">
.addPersonContainer {
    margin-bottom: 20px;
}
</style>
<script>
import { addLablePerson, delLablePerson, getLablePersonList } from '@/api/modular/fileStream/documentLabelManage'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, { dealResult } from '@/views/fileStream/documentManage/weixin/selectPerson'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
const columns = [
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: { customRender: 'name' },
    },
    {
        title: '账号',
        dataIndex: 'userId',
        key: 'userId',
        scopedSlots: { customRender: 'userId' },
    },
    {
        title: '企业名称',
        dataIndex: 'corpName',
        key: 'corpName',
        scopedSlots: { customRender: 'corpName' },
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
    },
]

export default {
    data() {
        return {
            data: [],
            columns,
        }
    },
    props: {
        tagActiveRow: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    components: {
        OpenData,
    },
    watch: {
        'tagActiveRow.id'() {
            if (this.tagActiveRow.id) {
                this.getUserList()
            } else {
                this.data = []
            }
        },
    },
    created() {
        init_wx()
        this.getUserList()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.addPersonContainer'], 66)
    },
    methods: {
        getDataType(node) {
            return node.userId ? 'userName' : 'departmentName'
        },
        uuid() {
            var s = []
            var hexDigits = '0123456789abcdef'
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
            }
            s[14] = '4'
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
            s[8] = s[13] = s[18] = s[23] = '-'
            var uuid = s.join()
            return uuid
        },
        delwithNode(node, unnids) {
            const createUnnids = () => {
                let id = this.uuid()
                while (unnids.includes(id)) {
                    id = this.uuid()
                }
                unnids.push(id)
                return id
            }
            node.uuid = createUnnids()
            if (node.wxCorpUserList && node.wxCorpUserList.length) {
                node.children = [...node.wxCorpUserList, ...node.children]
            }
            if (node.children && node.children.length === 0) delete node.children
            if (node.children && node.children.length) {
                node.children.forEach((ele) => {
                    this.delwithNode(ele, unnids)
                })
            }
        },
        formatResult(data) {
            const dataCopy = JSON.parse(JSON.stringify(data))
            const unnids = []
            dataCopy.forEach((element) => {
                this.delwithNode(element, unnids)
            })
            return dataCopy
        },
        confirm(_, { id }) {
            delLablePerson([
                {
                    id,
                },
            ])
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.getUserList()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        showSelectPerson() {
            if (this.tagActiveRow && this.tagActiveRow.id) {
                // this.$refs.SelectPerson.add('list', 0)
                selectPersonAndDepart({
                    type: 2,
                }).then((res) => {
                    const { resPart, result } = dealResult(res)
                    this.sub({
                        resPart,
                        result,
                    })
                })
            } else {
                this.$message.warning('请先选择标签！')
            }
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'deptId' : 'userId'
        },
        sub({ result, resPart }) {
            const array = [...result, ...resPart]
            const data = {
                labelId: this.tagActiveRow.id,
                userArray: array.map(({ userId, corpId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type: Number(type === 'departmentName'),
                })),
            }
            addLablePerson(data)
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.getUserList()
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getUserList() {
            getLablePersonList({
                labelId: this.tagActiveRow.id,
            })
                .then((res) => {
                    if (res.code === 200) {
                        this.$set(this, 'data', this.formatResult(res.data))
                        this.formatResult(res.data)
                        handleAppointEleHeight('#ygh-content-box', ['.addPersonContainer'], 66, res.data)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
    },
}
</script>
