
// 获取元素高度 num //父元素的上下外边距值
const getAppointEleHeight = (parentEle = '', childEle = [], num = 66, curentEle = '.ant-spin-container') => {
    if (!parentEle || !Array.isArray(childEle)) return
    const { ele, curent, childEleHeight } = handleElement(parentEle, childEle, num, curentEle)
    if (ele) {
        let curentChild = curent.children[0]
        if (!curentChild) return
        let height = ele.clientHeight;
        let data_init = curentChild.getAttribute('data-init')
        if (!data_init) {
            curentChild.setAttribute('data-init', 1)
            curentChild.setAttribute('id', 'appointEleHeight')
            curent.style = `height:${height - childEleHeight}px`
            curentChild.classList.add("appointEleHeight");
        }
    }
}
const getAppointEleHeightByData = (parentEle = '', childEle = [], num = 66, curentEle = '.ant-spin-container', isHasData = false) => {
    if (!parentEle || !Array.isArray(childEle)) return
    const { ele, curent, childEleHeight } = handleElement(parentEle, childEle, num, curentEle)
    if (ele) {
        let curentChild = curent.children[0]
        if (!curentChild) return
        let tableEle = curentChild.querySelector('.ant-table-body')
        if (!tableEle) return
        let height = ele.clientHeight;
        let curentChildHeight = tableEle.clientHeight + 50;
        let data_init = curentChild.getAttribute('data-init1')
        console.log('curentChildHeight++++++', curentChildHeight)
        if (!(curentChildHeight > height)) { //差值 
            if (!data_init) {
                curentChild.setAttribute('data-init1', 1)
                curent.style = `height:${height - childEleHeight}px;display: flex;flex-direction: column;justify-content: space-between;`
            }
        } else {
            curent.removeAttribute('style')
            curentChild.removeAttribute('data-init1')
        }
    }
}
const handleElement = (parentEle = '', childEle = [], num = 66, curentEle = '.ant-spin-container') => {
    let ele = window.document.querySelector(parentEle)
    let curent = window.document.querySelector(curentEle)
    let childEleHeight = childEle.map(item => {
        let cEle = window.document.querySelector(item)
        if (cEle) {
            let cHeight = cEle.clientHeight;
            return +cHeight
        }
    }).filter(Boolean).reduce((f, l) => {
        return f + l
    }, num)
    return {
        ele,
        curent,
        childEleHeight
    }
}
export const handleAppointEleHeight = (parentEle = '', childEle = [], num = 66, res = null, curentEle = '.ant-spin-container') => {
    return
    let isHasData = res ? Array.isArray(res) ? res.length > 0 : res?.data?.rows.length > 0 : false
    console.log('isHasData++++++', isHasData)
    let func = isHasData ? getAppointEleHeightByData : getAppointEleHeight
    setTimeout(() => {
        func(parentEle, childEle, num, curentEle, isHasData)
    }, 0);
}


// 移动端下载文件
export const uploadFileByMobile = (fileUrl = '') => {
    if (!fileUrl) return
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
        window.open(fileUrl)
    }
    if (isiOS) {

    }
}