import { axios } from '@/utils/request'

/**
 * 查询标签表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLablePage (parameter) {
  return axios({
    url: '/flow/documentLabel/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 新增标签人员
 *
 * @author xyl
 * @date 2022-07-17 13:31:00
 */
 export function addLablePerson (parameter) {
  return axios({
    url: '/flow/labelUser/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 查询标签人员列表
 *
 * @author xyl
 * @date 2022-07-17 13:31:00
 */
 export function getLablePersonList (parameter) {
  return axios({
    url: '/flow/labelUser/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询标签人员列表(分页)
 *
 * @author xyl
 * @date 2022-07-17 13:31:00
 */
 export function getLablePersonPage (parameter) {
  return axios({
    url: '/flow/labelUser/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 删除标签人员
 *
 * @author xyl
 * @date 2022-07-17 13:31:00
 */
 export function delLablePerson (parameter) {
  return axios({
    url: '/flow/labelUser/delete',
    method: 'POST',
    data: parameter
  })
}

/**
 * 标签表列表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLableList (parameter) {
  return axios({
    url: '/flow/documentLabel/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加标签表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLableAdd (parameter) {
  return axios({
    url: '/flow/documentLabel/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑标签表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLableEdit (parameter) {
  return axios({
    url: '/flow/documentLabel/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除标签表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLableDelete (parameter) {
  return axios({
    url: '/flow/documentLabel/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 导出标签表
 *
 * @author yx
 * @date 2022-07-17 13:31:00
 */
export function documentLableExport (parameter) {
  return axios({
    url: '/flow/documentLabel/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}
