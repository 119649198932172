import Vue from 'vue'
import {
  selectCorpGroupContact
} from './weixin'
export const corpId = Vue.ls.get('WX_corpId')
export const userId = Vue.ls.get('WX_data_set')?.userId
export const deptId = Vue.ls.get('WX_data_set')?.deptId
export const getCorpId = () => Vue.ls.get('WX_corpId')
export const getUserId = () => Vue.ls.get('WX_data_set')?.userId

const _formatUser = user => {
  const result = [...user]
  result.forEach(user => {
    user.userId = user.openUserId || user.id
  })
  return result
}
const _formatSelfPart = part => {
  const result = [...part]
  result.forEach(part => {
    part.corpId = corpId
    part.belongTo = 'self'
  })
  return result
}
/**
 * 选择人员与部门
 * @param {*} conf
 * @returns selectedUserList, selectedCorpGroupUserList
 */
export const selectPerson = conf => {
  console.log('配置文件++++++++',conf)
  return selectCorpGroupContact(conf)
    .then(res => {
      let {
        selectedUserList,
        selectedCorpGroupUserList,
        // eslint-disable-next-line prefer-const
        selectedCorpGroupDepartmentList,
        selectedDepartmentList
      } = res
      selectedUserList = _formatSelfPart(selectedUserList)
      selectedDepartmentList = _formatSelfPart(selectedDepartmentList)
      selectedUserList = _formatUser(selectedUserList)
      selectedCorpGroupUserList = _formatUser(selectedCorpGroupUserList)
      return {
        selectedUserList,
        selectedCorpGroupUserList,
        selectedCorpGroupDepartmentList,
        selectedDepartmentList
      }
    })
}

export const getUserListMsg = (list, index, val) => {
  let selectedOpenUserIds = []
  let selectedCorpGroupUserIds = []
  let selectedDepartmentIds = []
  let selectedCorpGroupDepartmentIds = []
  selectedOpenUserIds = val&&val.filter(j => j.belongTo === 'self' && j.type === 'userName')
    .map(j => j.userId)
  selectedCorpGroupUserIds = val&&val.filter(j => j.belongTo !== 'self' && j.type === 'userName')
    .map(j => ({
      corpId: j.corpId,
      openUserId: j.userId
    }))
  selectedDepartmentIds = val&&val.filter(j => j.belongTo === 'self' && j.type === 'departmentName')
    .map(j => j.id)
  selectedCorpGroupDepartmentIds = val&&val.filter(j => j.belongTo !== 'self' && j.type === 'departmentName')
    .map(j => ({
      corpId: j.corpId,
      departmentId: j.id
    }))
  return {
    selectedOpenUserIds,
    selectedCorpGroupUserIds,
    selectedDepartmentIds,
    selectedCorpGroupDepartmentIds,
    isList: list === 'list'
  }
}
export const dealResult = res => {
  const { selectedUserList,
    selectedCorpGroupDepartmentList,
    selectedDepartmentList,
    selectedCorpGroupUserList
  } = res
  const resPart = selectedDepartmentList.concat(selectedCorpGroupDepartmentList)
  resPart.forEach(j => {
    j.type = 'departmentName'
  })
  const result = selectedUserList.concat(selectedCorpGroupUserList)
  result.forEach(j => {
    j.type = 'userName'
  })
  return {
    resPart,
    result
  }
}
const initConf = {
  fromDepartmentId: 0,
  mode: 'multi',
  type: ['department', 'user']
}
export default (conf = {}) => {
  const config = {
    ...initConf,
    ...conf,
  }
  if(conf.type){
    config.type=conf.type==1?['department']:['user']
  }
  return selectPerson(config)
}
